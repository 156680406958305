import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/organisms/Layout'
import Seo from '@/components/organisms/Seo'
import PrivacyPolicy from '@/components/organisms/PrivacyPolicy'

interface Props {
	data?: {
		content: {
			seo?: SeoMetagagsType
			content?: PrivacyPolicyType
		}
	}
}

const PrivacyPolicyPage: React.FC<Props> = ({ data }) => {
	const { content } = data || {}

	return (
		<Layout>
			<div className="background privacy-policy">
				<Seo {...content?.seo} />
				<PrivacyPolicy {...content?.content} />
			</div>
		</Layout>
	)
}
export const PrivacyPolicyPageQuery = graphql`
	query {
		content: strapiPagePrivacyPolicy {
			content {
				content {
					data {
						content
					}
				}
			}
			seo {
				description
				og_image {
					url
				}
				title
			}
		}
	}
`

export default PrivacyPolicyPage
