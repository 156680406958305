import React from 'react'

import MarkdownContent from '@/components/atoms/MarkdownContent'

import * as styles from './styles.module.css'

const PrivacyPolicy: React.FC<PrivacyPolicyType> = ({ content }) => {
	return (
		<section className={styles.wrapper}>
			<div className="container">
				<div className={styles.content}>
					<MarkdownContent content={content} />
				</div>
			</div>
		</section>
	)
}

export default PrivacyPolicy
